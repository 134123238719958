import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { postPasswordChange } from '../../queries/postPasswordChange.ts';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { AxiosError } from 'axios';
import { getToken } from '../../utils.ts';
import { useNavigate } from 'react-router-dom';

export function ChangePassword() {
  const { t } = useTranslation();
  const [resetFailed, setResetFailed] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: (payload: object) => postPasswordChange(payload),
    onSuccess: () => {
      toast.success(`${t('settings.password-changed')}`);
      if (getToken()) {
        sessionStorage.clear();
      }
      navigate('/login/');
    },
    onError: (error: AxiosError) => {
      setServerErrorMessage(String(error?.response?.data) || '');
      toast.error(`${t('settings.password-changed-fail')}`);
      setResetFailed(true);
    },
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async function onSubmitChangePassword(event) {
    mutation.reset();
    event.preventDefault();
    const payload = {
      old_password: event.target.elements.oldPassword.value,
      new_password_1: event.target.elements.newPassword1.value,
      new_password_2: event.target.elements.newPassword2.value,
    };
    mutation.mutate(payload);
  }

  return (
    <Row className={'justify-content-center'}>
      <h1 className={'text-title-settings text-center mt-5'}>{t('settings.change-password')}</h1>
      <Col xs={6}>
        <Form onSubmit={onSubmitChangePassword}>
          {resetFailed ? (
            <Alert className={'mt-5'} variant="danger" onClose={() => setResetFailed(false)} dismissible>
              <p className={'login-error'}>{serverErrorMessage}</p>
            </Alert>
          ) : (
            <div></div>
          )}
          <Form.Group className="mb-3 mt-3">
            <Col>
              <Form.Label className={'text-bold'}>{t('settings.old-password')}</Form.Label>
              <Form.Control
                id={'oldPassword'}
                type="password"
                placeholder={t('settings.old-password')}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group className="mb-3">
            <Col>
              <Form.Label className={'text-bold'}>{t('settings.new-password')}</Form.Label>
              <Form.Control
                id={'newPassword1'}
                type="password"
                placeholder={t('settings.new-password')}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group className="mb-3">
            <Col>
              <Form.Label className={'text-bold'}>{t('settings.confirm-new-password')}</Form.Label>
              <Form.Control
                id={'newPassword2'}
                type="password"
                placeholder={t('settings.confirm-new-password')}
                required
              />
            </Col>
          </Form.Group>
          <Button className={'submit-button mt-3'} type={'submit'} disabled={mutation.isLoading}>
            <p>
              <img src={'/theme/media/pencil.svg'} width="25" height="25" alt="Pencil icon" />
              <span className={'ms-1 my-auto'}>{t('settings.change-password')}</span>
            </p>
          </Button>
        </Form>
      </Col>
    </Row>
  );
}
