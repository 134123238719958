import { useQueries, useQuery } from '@tanstack/react-query';
import { getShift, getVehicles } from '../queries/getVehicles.ts';
import { IVehicle } from '../interfaces/Vehicle.ts';

export const useVehiclesByTerritory = (territorySlug: string) => {
  return useQuery({
    queryKey: ['vehicles', territorySlug],
    queryFn: () => getVehicles(territorySlug),
    refetchInterval: 10000,
    retry: false,
  });
};

export const useShiftByVehicle = (territorySlug: string, vehicleId: number) => {
  return useQuery({
    queryKey: ['shift', vehicleId],
    queryFn: () => getShift(territorySlug, vehicleId),
    enabled: vehicleId !== undefined,
  });
};

export const useVehiclesShiftByTerritory = (territorySlug: string) => {
  const { data: vehicles } = useVehiclesByTerritory(territorySlug);
  useQueries({
    queries: vehicles?.data
      ? vehicles.data?.map((vehicle: IVehicle) => {
          return {
            queryKey: ['shift', vehicle.id],
            refetchInterval: 10000,
            queryFn: () => getShift(territorySlug, vehicle.id),
            select: (shift: { data: never }) => {
              vehicle['shift'] = shift.data[0];
            },
            retry: false,
            useErrorBoundary: false,
          };
        })
      : [],
  });
  return vehicles;
};
