import { useTranslation } from 'react-i18next';
import { IMission } from '../../interfaces/IMission.ts';
import { FormatDate, getLifecycleColors } from '../../utils.ts';
import { useParams } from 'react-router-dom';

interface Props {
  missions: IMission[];
}

export function MissionTable(props: Props) {
  const { t } = useTranslation();
  const territorySlug = useParams() as { territorySlug: string };
  return (
    <table className={'mission-history-table mt-0'}>
      <thead className={'mission-history-table-head'}>
        <tr>
          <th>
            <p className={'table-header-text'}>{t('mission-history.mission-id')}</p>
          </th>
          <th>
            <p className={'table-header-text'}>{t('mission-history.shift-id')}</p>
          </th>
          <th>
            <p className={'table-header-text'}>{t('mission-history.vehicle')}</p>
          </th>
          <th>
            <p className={'table-header-text'}>{t('mission-history.created-by')}</p>
          </th>
          <th>
            <p className={'table-header-text'}>{t('mission-history.created')}</p>
          </th>
          <th>
            <p className={'table-header-text'}>{t('mission-history.destination')}</p>
          </th>
          <th>
            <p className={'table-header-text text-center'}>{t('mission-history.status')}</p>
          </th>
        </tr>
      </thead>
      <tbody>
        {props.missions?.map((mission: IMission, i: number) => (
          <tr key={i}>
            <td className={'table-text'}>
              <a href={`#/mission-history/${territorySlug.territorySlug}/detail/${mission.id}/`}>
                {mission.id} <br /> <span className={'small'}>{mission.provider_identifier}</span>
              </a>
            </td>
            <td className={'table-text'}>{mission.shift_id || '-'}</td>
            <td className={'table-text'}>{mission.vehicle_display_identifier}</td>
            <td className={'table-text'}>{mission.created_by}</td>
            <td className={'table-text'}>{FormatDate(mission.created_at)}</td>
            <td className={'table-text'}>{mission.dispatch_destination_node_name}</td>
            <td className={'table-text text-center'}>
              <span
                className={'mission-lifecycle rounded-4'}
                style={getLifecycleColors(mission.lifecycle)}>
                {mission.lifecycle}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
