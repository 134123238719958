import Layout from '../components/Layout';
import { Territories as TerritoriesComponent } from '../components/Territories';

export function Territories() {
  return (
    <Layout>
      <TerritoriesComponent />
    </Layout>
  );
}
