import { LoginForm } from './LoginForm.tsx';
import { useTranslation } from 'react-i18next';
import { RegistrationCard } from '../Generic/RegistrationCard.tsx';

export function Login() {
  const { t } = useTranslation();
  return (
    <RegistrationCard>
      <h1 className={'sign-in text-center'}>{t('login.title')}</h1>
      <div className={'row d-flex justify-content-center mb-5'}>
        <div className={'col-10'}>
          <LoginForm />
        </div>
      </div>
    </RegistrationCard>
  );
}
