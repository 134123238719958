import { Alert, Button, Form as BsForm, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { refreshToken } from '../../queries/constants.ts';
import { useTranslation } from 'react-i18next';
import { setSessionExpiration, setToken } from '../../utils.ts';
import { useMutation } from '@tanstack/react-query';
import { postLogin } from '../../queries/postLogin.ts';
import { ILogin } from '../../interfaces/ILogin.ts';

export function LoginForm() {
  const navigate = useNavigate();
  const [loginFailed, setLoginFailed] = useState(false);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async function onSubmit(event) {
    setIsLoading(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      mutation.mutate({
        username: event.target.elements.email.value,
        password: event.target.elements.password.value,
      });
    }
  }

  const mutation = useMutation({
    mutationFn: (payload: ILogin) => postLogin(payload),
    onSuccess: data => {
      setLoginFailed(true);
      setToken(data.data.token);
      setSessionExpiration(6);
      refreshToken();
      navigate('/', { replace: true });
    },
    onError: () => {
      setLoginFailed(true);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  return (
    <BsForm onSubmit={onSubmit}>
      {loginFailed ? (
        <Alert className={'mt-3'} variant="danger" onClose={() => setLoginFailed(false)} dismissible>
          <p className={'login-error'}>{t('login.error')}</p>
        </Alert>
      ) : (
        <div></div>
      )}
      <BsForm.Group className={'mt-5'}>
        <BsForm.Control
          required
          type="text"
          name="email"
          placeholder={t('login.email')}
          className={'login-control shadow-sm'}
        />
        <BsForm.Control.Feedback type="invalid">{t('login.email-error')}</BsForm.Control.Feedback>
      </BsForm.Group>
      <BsForm.Group className={'mt-3'}>
        <BsForm.Control
          required
          type="password"
          name="password"
          placeholder={t('login.password')}
          className={'login-control shadow-sm'}
        />
        <BsForm.Control.Feedback type="invalid">{t('login.password-error')}</BsForm.Control.Feedback>
      </BsForm.Group>
      <div className="mt-1">
        <a className={'login-forgotten-password'} onClick={() => navigate('/reset-password/')}>
          {t('login.forgot')}
        </a>
      </div>
      <div className="d-grid gap-2">
        <Button type={'submit'} disabled={isLoading} className={'submit-button m-auto mb-1 mt-4'}>
          {isLoading ? (
            <span className={'d-flex justify-content-center'}>
              <Spinner size={'sm'} animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <span className={'ms-2'}>{t('login.submit')}</span>
            </span>
          ) : (
            <span>{t('login.submit')}</span>
          )}
        </Button>
      </div>
    </BsForm>
  );
}
