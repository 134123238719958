import { RegistrationCard } from '../Generic/RegistrationCard.tsx';
import { t } from 'i18next';
import { Alert, Button, Form as BsForm, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { postResetPasswordChange } from '../../queries/postResetPassword.ts';
import { useNavigate, useSearchParams } from 'react-router-dom';

export interface RequestPasswordChangeForm {
  password: string;
  token: string | null;
}

export function RequestPasswordChangeForm() {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: (payload: RequestPasswordChangeForm) => postResetPasswordChange(payload),
    onSuccess: () => {
      setShowErrorMessage(false);
      setShowSuccessMessage(true);
      navigate('/login/');
    },
    onError: () => {
      setShowSuccessMessage(false);
      setShowErrorMessage(true);
    },
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async function onSubmit(event) {
    event.preventDefault();
    mutation.mutate({
      password: event.target.elements.password.value,
      token: searchParams.get('token'),
    });
  }
  return (
    <RegistrationCard>
      <h1 className={'sign-in text-center'}>{t('reset-password-change.title')}</h1>
      <div className={'row d-flex justify-content-center mb-5'}>
        <div className={'col-10'}>
          <BsForm onSubmit={onSubmit}>
            {showSuccessMessage ? (
              <Alert
                className={'mt-3'}
                variant="success"
                onClose={() => setShowSuccessMessage(false)}
                dismissible>
                <p className={'login-error'}>{t('reset-password-change.success')}</p>
              </Alert>
            ) : (
              <></>
            )}
            {showErrorMessage ? (
              <Alert
                className={'mt-3'}
                variant="danger"
                onClose={() => setShowErrorMessage(false)}
                dismissible>
                <p className={'login-error'}>{t(`reset-password-change.error`)}</p>
              </Alert>
            ) : (
              <></>
            )}
            <BsForm.Group className={'mt-5'}>
              <BsForm.Control
                required
                type="password"
                name="password"
                placeholder={t('reset-password-change.password')}
                className={'login-control shadow-sm'}
              />
            </BsForm.Group>
            <div className="d-grid gap-2">
              <Button
                type={'submit'}
                disabled={mutation.isLoading}
                className={'submit-button m-auto mb-1 mt-4'}>
                {mutation.isLoading ? (
                  <span className={'d-flex justify-content-center'}>
                    <Spinner size={'sm'} animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <span className={'ms-2'}>{t('reset-password.submit')}</span>
                  </span>
                ) : (
                  <span>{t('reset-password.submit')}</span>
                )}
              </Button>
            </div>
          </BsForm>
        </div>
      </div>
    </RegistrationCard>
  );
}
