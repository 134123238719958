import { Button, Col, Offcanvas, Row } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { getVehiclesAutomataLogs, getVehiclesLogs } from '../../../queries/getVehicles.ts';
import { Key, useState } from 'react';
import { FormatTime, getLogLevel } from '../../../utils.ts';
import { ILog, VehicleLog } from '../../../interfaces/ILog.ts';
import { IVehicle } from '../../../interfaces/Vehicle.ts';
import { t } from 'i18next';
import { LOG_TYPE_AUTOMATA, LOG_TYPE_VEHICLE } from '../../../constants.ts';
import { TerritoryConfig } from '../../../interfaces/TerritoryConfig.ts';

interface Props {
  territorySlug: string;
  vehicle: IVehicle;
  territoryConfig: TerritoryConfig | null | undefined;
}

export function Log(props: Props) {
  const [show, setShow] = useState(false);
  const [logType, setLogType] = useState(LOG_TYPE_AUTOMATA);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { data: AutomataLogsData } = useQuery({
    queryKey: ['automataLogs', props.territorySlug, props.vehicle?.id],
    queryFn: () =>
      props.vehicle?.id ? getVehiclesAutomataLogs(props.territorySlug, props.vehicle?.id) : null,
    enabled: props.vehicle?.id !== undefined,
    refetchInterval: 10000,
  });

  function getCheckValueDisplay(checkValue: string) {
    if (checkValue === 'true') {
      return <img src={'/theme/media/success-check.svg'} width="15" height="15" alt="Success icon" />;
    } else if (checkValue === 'false') {
      return <img src={'/theme/media/failed-check.svg'} width="15" height="15" alt="Failed icon" />;
    } else {
      return checkValue;
    }
  }

  const { data: VehicleLogsData } = useQuery({
    queryKey: ['vehicleLogs', props.territorySlug, props.vehicle?.id],
    queryFn: () => (props.vehicle?.id ? getVehiclesLogs(props.territorySlug, props.vehicle?.id) : null),
    enabled: props.vehicle?.id !== undefined && props.territoryConfig?.vehicle.events_display === true,
    refetchInterval: 10000,
  });

  return (
    <>
      <Button className={'submit-button'} onClick={handleShow}>
        <p>
          <img src={'/theme/media/log.svg'} width="25" height="25" alt="Clock icon" />
          <span className={'ms-1 my-auto'}>Logs</span>
        </p>
      </Button>
      <Offcanvas className={'h-75'} show={show} placement={'bottom'} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className={'ms-2 card-subtitle'}>
            {t('logs.vehicle')} - {props.vehicle.display_identifier}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={'col text-end'}>
            <Button
              className={`submit-button${logType === LOG_TYPE_AUTOMATA ? '-reverse' : ''}`}
              onClick={() => setLogType(LOG_TYPE_AUTOMATA)}>
              <p>
                <span className={'my-auto'}>Automata</span>
              </p>
            </Button>
            <Button
              className={`ms-2 submit-button${logType === LOG_TYPE_VEHICLE ? '-reverse' : ''}`}
              onClick={() => setLogType(LOG_TYPE_VEHICLE)}>
              <p>
                <span className={'my-auto'}>{t('logs.vehicle')}</span>
              </p>
            </Button>
          </div>
          {AutomataLogsData?.status === 200 && logType === LOG_TYPE_AUTOMATA ? (
            AutomataLogsData?.data?.map((log: ILog, i: number) => (
              <div key={i}>
                <Row className={'mt-2'}>
                  <Col xs={1} className={'my-auto'}>
                    <p className={'text-basic ms-2'}>{FormatTime(log.timestamp)}</p>
                  </Col>
                  <Col xs={1} className={'my-auto'}>
                    <p>
                      <span className={'mission-lifecycle rounded-4'} style={getLogLevel(log.level)}>
                        {getLogLevel(log.level).name}
                      </span>
                    </p>
                  </Col>
                  <Col sm={5} lg={4} className={'my-auto'}>
                    <ul className={'m-0 p-0 ms-2'}>
                      {Object.keys(log.extra).map((key: string, index: Key) => (
                        <li className={'m-0 p-0'} key={index}>
                          <p className={'text-basic'}>
                            {key} : {getCheckValueDisplay(JSON.stringify(log.extra[key]))}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </Col>
                  <Col sm={5} lg={6} className={'p-0 my-auto'}>
                    <p className={'text-basic'}>{log.message}</p>
                  </Col>
                </Row>
                <hr />
              </div>
            ))
          ) : (
            <></>
          )}
          {VehicleLogsData?.status === 200 && logType === LOG_TYPE_VEHICLE ? (
            VehicleLogsData?.data?.map((log: VehicleLog, i: number) => (
              <div key={i}>
                <Row className={'mt-2'}>
                  <Col xs={1} className={'my-auto'}>
                    <p className={'text-basic ms-2'}>{FormatTime(log.start_datetime)}</p>
                  </Col>
                  <Col xs={1} className={'my-auto'}>
                    <span className={'mission-lifecycle rounded-4'} style={getLogLevel(log.level)}>
                      {getLogLevel(log.level).name}
                    </span>
                  </Col>
                  <Col xs={1} className={'my-auto'}>
                    <p>
                      <span className={'mission-lifecycle rounded-4'}>{log.code}</span>
                    </p>
                  </Col>
                  <Col xs={1} className={'my-auto'}>
                    <p>
                      <span className={'mission-lifecycle rounded-4'}>{log.category}</span>
                    </p>
                  </Col>
                  <Col sm={5} lg={6} className={'my-auto'}>
                    <ul className={'m-0 p-0 ms-2'}>
                      {Object.keys(log.context).map((key: string, index: Key) => (
                        <li className={'m-0 p-0'} key={index}>
                          <p className={'text-basic'}>
                            {key} : {JSON.stringify(log.context[key])}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
                <hr />
              </div>
            ))
          ) : (
            <></>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
