import { RegistrationCard } from '../Generic/RegistrationCard.tsx';
import { Alert, Button, Form as BsForm, Spinner } from 'react-bootstrap';
import { t } from 'i18next';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { postResetPassword } from '../../queries/postResetPassword.ts';

export interface resetPasswordPayload {
  email: string;
}

export function RequestResetForm() {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const mutation = useMutation({
    mutationFn: (payload: resetPasswordPayload) => postResetPassword(payload),
    onSuccess: () => {
      setShowErrorMessage(false);
      setShowSuccessMessage(true);
    },
    onError: () => {
      setShowSuccessMessage(false);
      setShowErrorMessage(true);
    },
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async function onSubmit(event) {
    event.preventDefault();
    mutation.mutate({ email: event.target.elements.email.value });
  }

  return (
    <RegistrationCard>
      <h1 className={'sign-in text-center'}>{t('reset-password.title')}</h1>
      <div className={'row d-flex justify-content-center mb-5'}>
        <div className={'col-10'}>
          <BsForm onSubmit={onSubmit}>
            {showSuccessMessage ? (
              <Alert
                className={'mt-3'}
                variant="success"
                onClose={() => setShowSuccessMessage(false)}
                dismissible>
                <p className={'login-error'}>{t('reset-password.success')}</p>
              </Alert>
            ) : (
              <></>
            )}
            {showErrorMessage ? (
              <Alert
                className={'mt-3'}
                variant="danger"
                onClose={() => setShowErrorMessage(false)}
                dismissible>
                <p className={'login-error'}>{t(`reset-password.error`)}</p>
              </Alert>
            ) : (
              <></>
            )}
            <BsForm.Group className={'mt-5'}>
              <BsForm.Control
                required
                type="email"
                name="email"
                placeholder={t('reset-password.email')}
                className={'login-control shadow-sm'}
              />
              <BsForm.Control.Feedback type="invalid">{t('login.email-error')}</BsForm.Control.Feedback>
            </BsForm.Group>
            <div className="d-grid gap-2">
              <Button
                type={'submit'}
                disabled={mutation.isLoading}
                className={'submit-button m-auto mb-1 mt-4'}>
                {mutation.isLoading ? (
                  <span className={'d-flex justify-content-center'}>
                    <Spinner size={'sm'} animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <span className={'ms-2'}>{t('reset-password.submit')}</span>
                  </span>
                ) : (
                  <span>{t('reset-password.submit')}</span>
                )}
              </Button>
            </div>
          </BsForm>
        </div>
      </div>
    </RegistrationCard>
  );
}
