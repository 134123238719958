import React from 'react';

interface Props {
  children: React.ReactNode;
}

export function RegistrationCard(props: Props) {
  return (
    <div className="container vh-100">
      <div className="row align-items-center h-100">
        <div className="col-5 mx-auto">
          <div className="card login-card justify-content-center shadow">
            <img
              src={'/theme/media/logos/logo-default.svg'}
              height="115"
              className={'padam-logo'}
              alt="Padam mobility - AV - logo"
            />
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}
