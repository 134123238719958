import { Container } from 'react-bootstrap';

export function Footer() {
  return (
    <div className={'footer d-flex fixed-bottom align-items-center shadow-lg'}>
      <Container fluid className={'text-end me-4 ms-4'}>
        <p className={'footer-text me-1'}>
          <span>2024 ©</span>
          <a
            className={'padam-link'}
            href={'https://www.padam-mobility.com/en/'}
            target="_blank"
            rel="noreferrer">
            {' '}
            Padam Mobility
          </a>
        </p>
      </Container>
    </div>
  );
}
