import React, { createContext, useState } from 'react';

type SelectedNodeContextProviderProps = {
  children: React.ReactNode;
};

interface ContextType {
  selectedNode: ISelectedNode | undefined;
  setSelectedNode: (selectedNode: { name: string | null }) => void;
}

export interface ISelectedNode {
  name: string;
}

const defaultValue = {
  selectedNode: undefined,
  setSelectedNode: () => {},
};
export const SelectedNodeContext = createContext<ContextType>(defaultValue);

export const SelectedNodeContextProvider = ({ children }: SelectedNodeContextProviderProps) => {
  const [selectedNode, setSelectedNode] = useState(undefined);

  return (
    <SelectedNodeContext.Provider
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value={{ selectedNode, setSelectedNode }}>
      {children}
    </SelectedNodeContext.Provider>
  );
};
