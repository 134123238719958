import axios from 'axios';
import {
  getMissionHistoryUrl,
  getMissionsDetailUrl,
  getMissionsUrl,
  header,
  patchMissionsUrl,
} from './constants.ts';

export async function getMissions(territorySlug: string, queryParameters: Record<string, string>) {
  return await axios.get(getMissionsUrl(territorySlug, queryParameters), { headers: header });
}

export async function getMissionsDetail(territorySlug: string, missionId: string) {
  return await axios.get(getMissionsDetailUrl(territorySlug, missionId), { headers: header });
}

export async function getMissionHistory(territorySlug: string, missionId: string) {
  return await axios.get(getMissionHistoryUrl(territorySlug, missionId), { headers: header });
}

export async function patchMissions(territorySlug: string, missionId: number, payload: object) {
  return await axios.patch(patchMissionsUrl(territorySlug, missionId), payload, { headers: header });
}
