import { MissionHistoryDetail as MissionHistoryDetailComponent } from '../components/MissionHistoryDetail';
import Layout from '../components/Layout';
import { useParams } from 'react-router-dom';
import { Suspense } from 'react';

export function MissionHistoryDetail() {
  const territorySlug = useParams() as { territorySlug: string };
  const missionId = useParams() as { missionId: string };

  return (
    <Layout>
      <Suspense>
        <MissionHistoryDetailComponent
          territorySlug={territorySlug.territorySlug}
          missionId={missionId.missionId}
        />
      </Suspense>
    </Layout>
  );
}
