import { Button, Card, Col, Spinner } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { Key, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { getTerritories } from '../../queries/getTerritories.ts';

interface Config {
  url: string;
}

export interface Territory {
  id: number;
  slug: string;
  config: Config;
  has_connections_active: boolean;
  backend_version: string;
}

export function Territories() {
  const { t } = useTranslation();
  const {
    data: territories,
    error: territoriesError,
    isLoading: territoriesLoading,
  } = useQuery({
    queryKey: ['territories'],
    queryFn: getTerritories,
    retry: false,
    enabled: !!sessionStorage.getItem('token'),
  });

  const navigate = useNavigate();

  useEffect(() => {
    const error = territoriesError as AxiosError;
    if (
      error !== null &&
      error !== undefined &&
      error.response?.status &&
      error.response?.status >= 500
    ) {
      navigate('/server-error/');
    }
  }, [territoriesError]);

  const onClick = (territory: Territory) => {
    navigate(`dashboard/${territory.slug}/`, {
      state: territory,
    });
  };

  return (
    <div>
      <h1 className={'text-center territory-page-title mt-5'}>{t('product.title')}</h1>
      <div className={'row d-flex mb-5 me-5 ms-5 pb-5'}>
        {territoriesLoading ? (
          <div className={'text-center mt-5 pt-5'}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {territories?.data.map((territory: Territory, i: Key) => (
              <Col className={'col-md-6 col-xl-4'} key={String(i)}>
                <Card className={'text-center pb-3 border-0 rounded-3 basic-shadow gx-5 mt-5 ms-3 me-3'}>
                  <div className={'text-end me-3 mt-3'}>
                    <p
                      className={
                        territory.has_connections_active
                          ? 'badge rounded-pill bg-success'
                          : 'badge rounded-pill bg-danger'
                      }>
                      {territory.has_connections_active ? 'Enabled' : 'Disabled'}
                    </p>
                  </div>
                  <h1 className={'territory-name-text mt-4'}>{territory.slug}</h1>
                  <Button
                    onClick={() => onClick(territory)}
                    disabled={!territory.has_connections_active}
                    className={'create-mission-btn m-auto ps-5 pe-5 mt-4'}>
                    Dashboard
                  </Button>
                  <hr className={'w-50 m-auto mt-3'} />
                  <a
                    className={'login-forgotten-password mt-4'}
                    target="_blank"
                    href={territory.config.url}
                    rel="noreferrer">
                    {territory.config.url}
                  </a>
                </Card>
              </Col>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
