import { Navigate } from 'react-router-dom';
import React from 'react';
import { getToken, sessionIsExpired } from '../../utils.ts';

interface Props {
  children: React.ReactNode;
}
export function ProtectedRoute(props: Props): React.ReactNode {
  if (getToken() && !sessionIsExpired()) {
    return props.children;
  }
  return <Navigate to="/login" />;
}
