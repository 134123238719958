import axios from 'axios';
import { passwordResetChangeUrl, passwordResetUrl } from './constants.ts';
import { resetPasswordPayload } from '../components/ResetPassword/RequestResetForm.tsx';
import { RequestPasswordChangeForm } from '../components/ResetPassword/RequestPasswordChangeForm.tsx';

export async function postResetPassword(payload: resetPasswordPayload) {
  return await axios.post(passwordResetUrl, payload);
}
export async function postResetPasswordChange(payload: RequestPasswordChangeForm) {
  return await axios.post(passwordResetChangeUrl, payload);
}
