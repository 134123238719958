import { Button, Col, Form, Pagination, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MissionTable } from './MissionTable.tsx';
import { useQuery } from '@tanstack/react-query';
import { getMissions } from '../../queries/getMissions.ts';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  territorySlug: string;
}

export function MissionHistory(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [maxPage, setMaxPage] = useState(1);
  const [displayFilters, setDisplayFilters] = useState(false);
  const [queryParameters, setQueryParameters] = useState<Record<string, string>>({ page: String(1) });

  const { data: missionsData } = useQuery({
    queryKey: ['missions', props.territorySlug, queryParameters],
    queryFn: () => getMissions(props.territorySlug, queryParameters),
    keepPreviousData: true,
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async function handleFilter(event) {
    event.preventDefault();
    const queryParameters: Record<string, string | number> = {
      provider_identifier: event.target.elements.missionID.value,
      shift_id: event.target.elements.shiftID.value,
      vehicle_display_identifier: event.target.elements.vehicleID.value,
      created_by: event.target.elements.createdByAutomata.checked ? 'AUTOMATA' : '',
      date: event.target.elements.date.value,
      page: 1,
    };
    for (const k in queryParameters) {
      queryParameters[k] === '' ? delete queryParameters[k] : null;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setQueryParameters(queryParameters);
  }

  useEffect(() => {
    setQueryParameters({ page: String(1) });
  }, [displayFilters]);

  useEffect(() => {
    if (missionsData?.data !== undefined) {
      const count = Number(missionsData?.data['count']);
      setMaxPage(Math.ceil(count / 10));
    }
  }, [missionsData, queryParameters]);
  function nextPage() {
    if (Number(queryParameters.page) < maxPage) {
      setQueryParameters({ ...queryParameters, page: String(Number(queryParameters.page) + 1) });
    }
  }
  function previousPage() {
    if (Number(queryParameters.page) > 1) {
      setQueryParameters({ ...queryParameters, page: String(Number(queryParameters.page) - 1) });
    }
  }
  function firstPage() {
    setQueryParameters({ ...queryParameters, page: String(1) });
  }
  function lastPage() {
    setQueryParameters({ ...queryParameters, page: String(maxPage) });
  }

  const onClick = (territorySlug: string) => {
    navigate(`/dashboard/${territorySlug}/`);
  };

  return (
    <>
      <Col className={'mission-history-container'}>
        <Row>
          <Col className={'m-0 p-0 mt-4 ms-4 me-4'}>
            <p className={'card-title'}>
              <img src={'/theme/media/mission-icon.svg'} width="56" height="42" alt="Mission icon" />
              <span className={'ms-3'}>{t('mission-history.title')}</span>
            </p>
          </Col>
        </Row>
        <Row className={'mb-4 mt-4 d-flex align-items-center'}>
          <Col className={'m-0 p-0 ms-4 me-4'}>
            <a className={'text-backlink'} onClick={() => onClick(props.territorySlug)}>
              <span className={'ms-1 my-auto'}>{props.territorySlug}</span>
            </a>
          </Col>
          <Col className={'m-0 p-0 ms-4 me-4 text-end'}>
            <Button
              className={'submit-button-reverse'}
              onClick={() => setDisplayFilters(!displayFilters)}>
              <p>
                <img src={'/theme/media/filters.svg'} width="15" height="15" alt="Filters icon" />
                <span className={'ms-1 my-auto'}>{t('mission-history.filters')}</span>
              </p>
            </Button>
          </Col>
        </Row>
        {displayFilters ? (
          <Form className={'m-0 p-0'} onSubmit={handleFilter}>
            <Row className="align-items-center m-0 p-0 mb-4">
              <Col xs="2">
                <Form.Control id="missionID" placeholder="Mission ID" />
              </Col>
              <Col xs="2">
                <Form.Control id="shiftID" placeholder="Shift ID" />
              </Col>
              <Col xs="2">
                <Form.Control id="vehicleID" placeholder="Vehicle ID" />
              </Col>
              <Col xs="2">
                <Form.Control type="date" id="date" />
              </Col>
              <Col xs="2">
                <Form.Check
                  type="checkbox"
                  id="createdByAutomata"
                  label={t('mission-history.created-by') + ' ' + 'Automata'}
                />
              </Col>
              <Col xs="2">
                <Button type={'submit'} className={'submit-button'}>
                  <p>
                    <span className={'my-auto'}>{t('mission-history.apply')}</span>
                  </p>
                </Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <></>
        )}
        <Row>
          <Col className={'m-0 p-0 ms-4 me-4'}>
            <MissionTable missions={missionsData?.data['results']} />
          </Col>
        </Row>
        <Row>
          <Col className={'m-0 p-0 mt-2 ms-4 me-4 d-flex justify-content-center'}>
            <Pagination color={'alert'}>
              <Pagination.First onClick={() => firstPage()} />
              <Pagination.Prev onClick={() => previousPage()} />
              <Pagination.Item>{queryParameters.page}</Pagination.Item>
              <Pagination.Next onClick={() => nextPage()} />
              <Pagination.Last onClick={() => lastPage()} />
            </Pagination>
          </Col>
        </Row>
      </Col>
    </>
  );
}
