import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getMissionHistory, getMissionsDetail } from '../../queries/getMissions.ts';
import { FormatDate, getLifecycleColors } from '../../utils.ts';

interface Props {
  territorySlug: string;
  missionId: string;
}

interface MissionHistory {
  lifecycle: string;
  updated_at: string;
  updated_by: string;
}
export function MissionHistoryDetail(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: mission } = useQuery({
    queryKey: ['mission', props.territorySlug, props.missionId],
    queryFn: () => getMissionsDetail(props.territorySlug, props.missionId),
  });
  const { data: missionHistory } = useQuery({
    queryKey: ['mission-history', props.territorySlug, props.missionId],
    queryFn: () => getMissionHistory(props.territorySlug, props.missionId),
  });

  function exportMissionHistory() {
    const csvString = [
      ['lifecycle', 'created_at', 'created_by'],
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...missionHistory?.data.lifecycles.map((history: MissionHistory) => [
        history.lifecycle,
        history.updated_at,
        history.updated_by,
      ]),
    ]
      .map(row => row.join(','))
      .join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `mission-${props.missionId}-history`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const onClick = (territorySlug: string) => {
    navigate(`/mission-history/${territorySlug}/`);
  };
  return (
    <>
      <Col className={'mission-history-container'}>
        <Row>
          <Col className={'m-0 p-0 mt-4 ms-4 me-4'}>
            <p className={'card-title'}>
              <img src={'/theme/media/mission-icon.svg'} width="56" height="42" alt="Mission icon" />
              <span className={'ms-3'}>
                {t('mission-history-detail.title') + ' #' + props.missionId}
              </span>
            </p>
          </Col>
        </Row>
        <Row className={'mb-4 mt-4 d-flex align-items-center'}>
          <Col className={'m-0 p-0 ms-4 me-4'}>
            <a className={'text-backlink'} onClick={() => onClick(props.territorySlug)}>
              <span className={'ms-1 my-auto'}>{t('mission-history.title')}</span>
            </a>
          </Col>
          <Col className={'m-0 p-0 ms-4 me-4 text-end'}>
            <Button className={'submit-button'} onClick={exportMissionHistory}>
              <p>
                <img src={'/theme/media/export.svg'} width="18" height="18" alt="Filters icon" />
                <span className={'ms-1 my-auto'}>{t('mission-history-detail.export')}</span>
              </p>
            </Button>
          </Col>
        </Row>
        <Row className={'mb-4 mt-4'}>
          <Col xs={4} className={'m-0 p-0 ps-4 pe-4'}>
            <Card className={'border-0 rounded-3 mission-history-detail-card'}>
              {mission?.data ? (
                <div className={'my-auto'}>
                  <Row>
                    <Col className={'ms-3'}>
                      <p className={'card-subtitle my-auto'}>{t('mission-history-detail.mission')}</p>
                    </Col>
                    <Col>
                      <p className={'text-basic my-auto'}>#{mission.data.id}</p>
                    </Col>
                  </Row>
                  <Row className={'mt-2'}>
                    <Col className={'ms-3'}>
                      <p className={'card-subtitle my-auto'}>{t('mission-history-detail.lifecycle')}</p>
                    </Col>
                    <Col>
                      <span
                        className={'mission-lifecycle rounded-4 my-auto'}
                        style={getLifecycleColors(mission.data.lifecycle)}>
                        {mission.data.lifecycle}
                      </span>
                    </Col>
                  </Row>
                  <Row className={'mt-2'}>
                    <Col className={'ms-3'}>
                      <p className={'card-subtitle my-auto'}>{t('mission-history-detail.vehicle')}</p>
                    </Col>
                    <Col>
                      <p className={'text-basic my-auto'}>{mission.data.vehicle_display_identifier}</p>
                    </Col>
                  </Row>
                  <Row className={'mt-2'}>
                    <Col className={'ms-3'}>
                      <p className={'card-subtitle my-auto'}>Shift ID</p>
                    </Col>
                    <Col>
                      <p className={'text-basic my-auto'}>{mission.data.shift_id || '-'}</p>
                    </Col>
                  </Row>
                  <Row className={'mt-2'}>
                    <Col className={'ms-3'}>
                      <p className={'card-subtitle my-auto'}>{t('mission-history-detail.created_at')}</p>
                    </Col>
                    <Col>
                      <p className={'text-basic my-auto'}>{FormatDate(mission.data.created_at)}</p>
                    </Col>
                  </Row>
                  <Row className={'mt-2'}>
                    <Col className={'ms-3'}>
                      <p className={'card-subtitle my-auto'}>{t('mission-history-detail.created_by')}</p>
                    </Col>
                    <Col>
                      <p className={'text-basic my-auto'}>{mission.data.created_by}</p>
                    </Col>
                  </Row>
                  <Row className={'mt-2'}>
                    <Col className={'ms-3'}>
                      <p className={'card-subtitle my-auto'}>
                        {t('mission-history-detail.destination_node')}
                      </p>
                    </Col>
                    <Col>
                      <p className={'text-basic my-auto'}>
                        {mission.data.dispatch_destination_node_name || '-'}
                      </p>
                    </Col>
                  </Row>
                </div>
              ) : (
                <></>
              )}
            </Card>
          </Col>
          <Col xs={4} className={'m-0 p-0'}>
            <Card className={'border-0 rounded-3 mission-history-detail-card'}>
              {missionHistory?.data ? (
                <table className={'mt-0 history-detail-table rounded-3'}>
                  <thead className={'mission-history-table-head'}>
                    <tr>
                      <th className={'table-header-text'}>{t('mission-history-detail.created_at')}</th>
                      <th className={'table-header-text'}>{t('mission-history-detail.created_by')}</th>
                      <th className={'table-header-text text-center'}>
                        {t('mission-history-detail.lifecycle')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className={'history-detail-table-body'}>
                    {missionHistory?.data.lifecycles?.map((history: MissionHistory, i: number) => (
                      <tr key={i}>
                        <td className={'text-basic'}>{FormatDate(history.updated_at)}</td>
                        <td className={'text-basic'}>{history.updated_by}</td>
                        <td className={'text-center'}>
                          <span
                            className={'mission-lifecycle rounded-4'}
                            style={getLifecycleColors(history.lifecycle)}>
                            {history.lifecycle}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className={'mx-auto my-auto'}>{t('mission-history-detail.no-mission-history')}</p>
              )}
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
}
