import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export function NotFound() {
  const navigate = useNavigate();

  return (
    <>
      <Container className={'vh-100 d-flex align-items-center justify-content-center'} fluid>
        <Row>
          <Col>
            <div>
              <img src={'/theme/media/illustrations/404.png'} height="450px" alt="404 icon" />
            </div>
            <div className={'text-center mt-5'}>
              <Button
                type={'submit'}
                className={'submit-button m-auto mb-3 mt-2'}
                onClick={() => navigate(-1)}>
                <p>
                  <span>Back</span>
                </p>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
